.sn {
  font-size: 0px;
  @include flex-it;
  @include flex-align-item-center;

  a {
    background: url("#{$images}/sn-sprites.png") center center repeat-y;
    background-size: 20px auto;
    width: 40px;
    height: 40px;
    //@include rounded(20px);
    margin: 0;
    display: inline-block;
    border: 0;
    @include transition(.2s);
    @include opacity(1);
    text-decoration: none;
    margin: 0 4px;
    //@include opacity(.65);
    background: transparent;
    border: 0;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    svg {
      display: none;
    }

    .svg & {
      @include flex-it;
      @include flex-align-item-center;
      @include flex-justify-center;
      //background: $color-accent;
      border: 0;

      svg {
        width: 100%;
        height: auto;
        max-height:20px;
        max-width: 20px;
        display: block;

        .st0 {
          @include transition();
          fill: $color-base;
        }
      }

      &.fb {
        svg {
          //width: 8px;
          //height: 13px;
        }
      }

      &:hover {
        background: $color-accent;
        svg {
          .st0 {
            fill: #fff;
            //fill: #fff;
          }
        }
      }
    }
  }

  &.on-color {
    a {
      .svg & {
        svg {
          .st0 {
            fill: #fff;
          }
        }
      }
    }
  }
}

.sn a.twitter {
  background-position: 6px 10px;
}

.sn a.fb {
  background-position: 6px -83px;
}

.sn a.google {
  background-position: 6px -173px;
}

.sn a.instagram {
  background-position: 6px -263px;
}

.sn a:hover {
  @include opacity(1);
}
