.blog-filters {
  min-height: 55px;
  margin-bottom: 50px;
  div[class*="col-"] {
    text-align: center;
    &:first-child {
      text-align: right;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    &:last-child {
      text-align: left;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }
  .facetwp-facet {

  }
  .facetwp-facet-category {
    margin: 0;
    padding: 5px 0 0 0;
    text-align: right;

    .fs-label {
      text-align: left;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .date {
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;

    @include media-breakpoint-down(sm) {
      display: block;
    }
    .facetwp-type-date_range {
      text-align: left;
      margin: 0;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      .facetwp-date {
        margin: 5px;
        width: 130px;
      }
    }
  }
}



article.post {
  margin-top: 30px;
  time, p.author {
    display: inline-block;
    font-size: 14px;
    color: $color-light-text;
  }
  span.del {
    color: $color-light-text;
    @include opacity(.5);
  }

  header {
    padding: 0 0 15px;
    h1::after {
      content: "\A";
      white-space: pre;
    }
  }

  .thumb {
    margin-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 500px;
      width: auto;
      height: auto;
    }
  }
  header {
    text-align: center;
  }
}

.post-list-text {
  margin-top: 50px;

  article.post {
    border-bottom: 1px solid $color-hr;
    padding: 30px 0 50px;
    margin: 30px 0;

    header {
      text-align: left;
    }

    .thumb {
      margin-bottom: 20px;
      img {
        max-width: 100%;
        max-height: 500px;
        width: auto;
        height: auto;
      }
    }
  }
  .articles {
    article.post:last-child {
      border: 0;
    }
  }

  .fwp-load-more {
    margin: 70px auto 30px;
    display: block;

  }
}


section.blog-filters {
  margin-bottom: 50px;
  .cats {
    select {
      width: 100%;
    }
  }
  .search-form {
    label {
      padding: 0;
      margin: 0;
      input.search-field {
        padding: 8px !important;
        font-size: 16px;
        margin: 0;
        width: auto !important;
        display: block !important;
        box-sizing: border-box;
      }
    }
    .search-submit {
      display: none;
    }
  }
}


.posts-cat-focus {
  h3 {
    text-transform: none;
    letter-spacing: 0;
    font-size: 18px;
  }
  img.thumb {
    width: 100%;
    margin-bottom: 15px;
  }
  .row {
    .row {
      margin: 0 -25px;
      padding: 0;
    }
  }
}



.posts {
  h2, h3 {
    line-height: 130%;
  }
  article {
    border-bottom: 1px solid $color-hr;
    padding: 15px 0;
    margin: 15px 0;
  }
  article:last-child {
    border: none;
  }
}


.post-list {
  .row {
    padding: 0;
    .col-md-6 {
      border: 0;
    }
  }
  .post-block {
    padding: 0;
    background-size: cover;
    background-position: center center;
    height: 300px;
    text-align: left;
      a.article {
        height: 100%;
        background: rgba(0, 0, 0, .3);
        padding: 10px 10px 10px;
        margin: 0;
        display: block;
        @include flex-it;
        @include flex-align-item-end;
        header {
          text-align: left;
          b {
            font-weight: 400;
            font-size: 17px;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, .1);
        }
        &, h2 {
          color: #fff;
        }
        h2 {
          margin: 0;
          padding: 0;
        }
      }
    }
  }



//
// Social buttons
//
.entry-share {
  ul.entry-share-btns {
    li.entry-share-btn {
      a {
        background: $color-accent;
        color: #fff;
        &:hover {
          background: $color-base;
        }
      }
    }
  }
}



section.social-media-share {
  margin-top: 100px;
  margin-bottom: 80px;
  text-align: center;
}





//
// Pager
//
.pager {
  background: darken(#F9FBFD, 2%);
  .row {
    .col {
      padding: 0;
    }
    padding: 0;
    nav.posts-navigation {
      .nav-links {
        width: 100%;
        @include flex-it;
        @include flex-align-item-center;
        div {
          width: 50%;
          a {
            display: block;
            padding: 50px;
            background: $color-base;
            text-align: center;
            font-family: $font-menu;
            color: #fff;
            font-size: 22px;
            &:hover {
              background: $color-accent;
            }
            @include media-breakpoint-down(sm) {
              font-size: 19px;
              padding: 30px 20px;
            }
          }
        }
      }
    }
  }
}




section.recent-featured, section.post-list {
  h2 {
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-size: 18px;
    color: #fff;
  }
  a.view-all {
    display: inline-block;
    color: $color-base;
    border-bottom: 1px solid rgba(0,0,0,.3);
    text-transform: uppercase;
    letter-spacing: .05em;
    font-size: 14px;
  }
  .heading {
    background: $color-accent;
  }

  .row.list-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  div[class*="col-"] {
    padding-top: 25px;
    padding-bottom: 25px;
    a.inner {
      border: 0;
      border-top: 3px solid transparent;
      display: block;
      background: #fff;
      height: 100%;
      text-align: left;
      padding: 10px;
      .categories {
        color: $color-accent;
        text-transform: uppercase;
        letter-spacing: .1em;
        font-weight: 500;
        font-size: 14px;
      }
      h3 {
        @include transition();
      }

      &:hover {
        border-color: $color-accent;
        h3 {
          color: $color-accent;
        }
      }
    }
  }

  time {
    color: rgba(0, 0, 0, .5);
    font-size: 15px;
  }
}
