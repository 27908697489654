$color-base: #000000;
$color-text: #000000;
$color-subtle: rgba(0, 0, 0, 0.4);
$color-accent: #FED307;
$color-admin-accent: #FED307;
$color-headings: $color-base;
$color-accent-secondary: #f6f5f4;
$color-accent-third: #3ca348;
$color-overlay-rgba: rgba(0, 0, 0, 0.6);
$color-neutral: #a0a0a0;
$color-body-grey: #b5b5b5;
$color-light-text: rgba(0, 0, 0, 0.5);
$color-light-text-forms: rgba(0, 0, 0, 0.55);
$color-light: #efefef;
$color-grey: #aeb8c4;
$color-subtle-background: #f7f5f2;
$color-subtle-background-secondary: #ebf3f7;
$color-on-base: #889998;
$color-grey-on-base: #637575;
$color-light-box: #f7f7f7;
$color-hr: #d8ddd9;
$color-hamburger: #fff;
$color-hamburger-menu-open: $color-base;

// Special colors
$color1: $color-accent;
$color2: #a88f63;
$color3: #6da3a2;
$color4: #fff;
$color5: #fff;
$color6: #fff;

// Form colors
$color-form-bg: #fff;
$color-form-border: $color-hr;
$color-form-border-size: 1px;
$color-form-select-hover: $color-accent;
$color-form-select-active: $color-accent;

$color-table-heading-bg: #ccdae2;

@font-face {
  font-family: "CarnabyStreet-Clean";
  src: url("../../assets/fonts/CarnabyStreet-Clean.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/CarnabyStreet-Clean.otf") format("opentype"),
    url("../../assets/fonts/CarnabyStreet-Clean.woff") format("woff"),
    url("../../assets/fonts/CarnabyStreet-Clean.ttf") format("truetype"),
    url("../../assets/fonts/CarnabyStreet-Clean.svg#CarnabyStreet-Clean")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$font-sans: proxima-nova, sans-serif;
$font-serif: proxima-nova, sans-serif;
$font-body: proxima-nova, sans-serif;
//$font-headline: "futura-pt-condensed", "futura-pt", sans-serif;
$font-headline: "CarnabyStreet-Clean", sans-serif;
//$font-menu: "futura-pt-condensed", "futura-pt", sans-serif;
$font-menu: proxima-nova, sans-serif;
$images: "../../assets/images";
