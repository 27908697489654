

.team {
  .employee {
    margin-top: 25px;
    margin-bottom: 25px;
    h3 {
      margin-bottom: 4px;
    }
    .title {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: .1em;
    }
    a.butt {
      margin-top: 10px;
    }
    img {
      max-width: 400px;
      width: 100%;
      height: auto;
      margin: 0 auto 20px;
    }

  }
}

section.filters {
  text-align: center;
  .facet-reset {
    @include opacity(0);
    &.inplace {
      @include opacity(1);
    }
  }

  .reset-filters {
    margin: 0;
  }

  .facetwp-type-autocomplete {
    input {
      margin-right: 2px !important;
    }
  }

}


section.meet-the-team {
  .team-headshot {
    text-align: center;
    width: 350px;
    height: 350px;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      @include rounded(50%);
      position: relative;
      top: 10px;
      @include transition();
      @include opacity(0);
    }
  }
  &.showing {
    img {
      top: 0;
      @include opacity(1);
    }
  }
  h3 {
    margin-top: 25px;
  }

  .refresh-team {
    color: #fff;
    padding: 8px 14px;
    position: relative;
    top: -100px;
    border: 0;
    background: $color-accent url("#{$images}/icon-swap.svg") center center no-repeat;
    background-size: 25px auto;
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 40px;
    height: 40px;
    @include rounded(20px);
    margin: 0 auto;
    &:hover {
      background-color: $color-base;
    }
  }
}
