//
// Icons
//
.icon {
  margin: 0 auto;
  margin-bottom: 15px;
}



.anim-icons {
  width: 200px;
  margin-bottom: 30px;
}
