/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@mixin butt {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid $color1;
  padding: 20px 30px;
  @include transition(0.2s ease-in-out);
  font-weight: 600;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  line-height: 100%;
  background-color: transparent;
  color: $color-text;
  @include rounded(2px);
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.butt,
a.butt:active,
a.butt:hover,
a.butt:link,
a.butt:visited,
button,
input.button {
  @include butt;
}

@mixin butt-hover {
  background-color: $color1;
  color: $color-text;
}

@mixin disabled {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

a:hover.butt,
button:hover,
input.button:hover {
  @include butt-hover;
}

input[type="submit"] {
  @include butt();
  @include transition();
  &:hover {
    @include butt-hover();
  }

  &:disabled {
    @include disabled();
    cursor: default;
  }
}
