$caret-width-base: 4px;
$caret-width-large: 5px;

/* =================================================
     Header
   ============================================== */
.header-container {
  margin: 0 auto !important;
}
.header-container,
.header {
  max-width: 2000px;
}
.header {
  padding: 15px 8px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  box-sizing: border-box;
  background-color: #fff;
  @include drop-shadow();
  //height: 95px;
  @include transition();

  .open-status {
    color: #fff;
    font-size: 16px;
    min-height: 65px;
    padding-right: 60px;
    padding: 10px 60px 10px 10px;
    @include flex-it;
    @include flex-justify-start;
    @include flex-align-item-center;
    a {
      border-bottom: 1px solid $color-accent;
      color: #fff;
      font-weight: 500;
    }
  }

  html.hero &,
  body.home & {
    background-color: transparent;
    @media handheld, only screen and (max-width: 992px) {
      background-color: $color-accent;
    }
  }

  &.scrollnav {
    a.logo {
      svg {
        .st0 {
          //fill: #fff;
        }
        .st1 {
          //fill: #fff;
        }
      }
    }
  }


  
  html.hero &,
  body.home & {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
    @include drop-shadow();

    @media handheld, only screen and (max-width: 992px) {
      padding: 0;
    }
    a.logo {
      svg {
        .st0 {
          fill: #fff;
        }
        .st1 {
          fill: #fff;
        }
      }
    }
    .nav {
      ul {
        & > li {
          a.nav-link {
            color: #fff;
            &:hover {
              color: rgba(255, 255, 255, 0.85);
            }
            @media handheld, only screen and (max-width: 992px) {
              color: $color-base;
              &:hover {
                color: $color-base;
              }
            }
          }

          &.dropdown > a::after {
            border-top: 5px solid rgba(255, 255, 255, 0.8);
            @media handheld, only screen and (max-width: 992px) {
              border-top: 5px solid rgba(0, 0, 0, 0.8);
            }
          }

          @media handheld, only screen and (min-width: 992px) {
            &.order {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  body.admin-bar & {
    top: 30px;
  }

  .container-fluid {
    &,
    .row {
      padding-left: 0;
      margin-left: 0;
      padding-right: 0;
      margin-right: 0;

      @media handheld, only screen and (max-width: 992px) {
        div[class*="col-"] {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  a {
    border: none;
  }

  a.cart {
    display: inline-block;
  }
  a.logo {
    width: 250px;
    display: block;
    svg {
      @include transition();
      width: 250px;
      height: auto;
      .st0 {
        fill: #fed307;
      }
      .st1 {
        fill: #5b412d;
      }
    }
  }

  html &.scrollnav {
    background-color: rgba(255, 255, 255, 0.95);
    padding-top: 10px;
    padding-bottom: 10px;
    @include drop-shadow();

    .open-status {
      color: $color-text;
      a {
        color: $color-text;
      }

      @media handheld, only screen and (max-width: 992px) {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }

    @media handheld, only screen and (max-width: 992px) {
      padding: 0;
    }
    .nav {
      ul {
        & > li {
          a.nav-link {
            color: $color-base;
            &:hover {
              color: rgba(0, 0, 0, 0.85);
            }
          }
          &.order a.nav-link {
            color: $color-text;
            &:hover {
              color: #fff;
            }
          }

          &.dropdown > a::after {
            border-top: 5px solid rgba(0, 0, 0, 0.8);
          }

          @media handheld, only screen and (min-width: 992px) {
            &.order {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

// Mobile
@media handheld, only screen and (max-width: 992px) {
  .header {
    padding: 0;
    height: 95px;
    //position: fixed;
    a.logo {
      @media handheld, only screen and (max-width: 992px) {
        padding: 10px 0 10px 30px;
        svg {
          width: 100px;
          height: auto;
        }
      }
    }
    html &.scrollnav {
      background: $color-accent;
    }
  }
  body.home {
    .header {
      height: 65px;
    }
  }
}

div.wrap {
  clear: both;
}
.wrap {
  clear: both;
  padding-top: 140px !important;
  box-sizing: border-box;
  min-height: 92vh;
  max-width: 2000px;
  margin: 0 auto;

  /* Based on the height of the menu */
  @media handheld, only screen and (max-width: 1000px) {
    padding-top: 75px !important;
    /* Based on the height of the menu */
  }
}

html.hero .wrap {
  padding-top: 0 !important;
}

body.home .wrap {
  padding-top: 0 !important;
}

.header.top {
  background-color: #fff;
}

.headroom {
  @include transition();
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}
