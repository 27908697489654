.faqs {
  .faq {
    padding: 10px 0;
    border-bottom: 1px solid $color-hr;
    h3 {
      margin: 10px 0;
      cursor: pointer;
      @include transition();
      line-height: 130%;
      font-size: 20px;
      &:hover {
        color: $color-accent;
      }
    }
    h3::before,
    h3.showing::before {
      padding: 0 0 15px 15px;
    }

    h3::before {
      float: right;
      content: "+";
      color: $color-accent-secondary;
    }
    h3.showing::before {
      content: "-";
      float: right;
      color: $color-accent-secondary;
    }
    .answer {
      transition: none;
      p:last-child {
        margin: 0;
      }
    }
  }
  .faq:last-child {
    border: none;
  }
}
