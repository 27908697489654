.carousel.slide {
  height: 400px;
  background: rgba(0, 0, 0, 0.8);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.8));
  max-width: 2200px;
  margin: 0 auto;
  padding-bottom: 0;
  font-size: 0;
  line-height: 100%;

  .carousel-inner {
    .overlay {
      min-height: 650px;
      padding: 0;
    }
    .overlay {
      @include flex-it;
      @include flex-align-item-center;
      @include flex-justify-start;
    }
    .carousel-item.slide-bottom {
      .overlay {
        @include flex-align-item-end;
      }
    }
    .content {
      //max-width: 800px;
      padding: 20px 0 0;
      margin: 0 0;
      text-align: center;
      margin: 0 auto;

      h1,
      h2 {
        font-size: 65px;
        @include media-breakpoint-down(sm) {
          font-size: 45px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-left: 30px;
        margin-left: 0;
      }
    }
    .carousel-item {
      &.load-bg-img {
        background: url("#{$images}/loading.gif") center center no-repeat;
        background-size: 60px 60px;
      }
      &.load-bg-img.loaded {
        background-size: cover;
        background-position: center center;
        .overlay {
          background: $color-overlay-rgba;
        }
      }
    }
    img {
      margin: 50px auto 0;
      padding: 0;
      display: block;
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }

  a.carousel-control-prev,
  a.carousel-control-next {
    width: 50px;
    background: transparent;
    border: none;
    @include opacity(0.8);
    &:hover {
      @include opacity(1);
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  a.carousel-control-prev {
  }
}

.btslider {
  .carousel {
    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }
    p {
      position: static !important;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin: 15px 0 0 0;
    }
  }
}

.intro-slideshow {
  &,
  .carousel-item,
  .carousel-inner,
  .carousel.slide,
  a.carousel-control-prev,
  a.carousel-control-next {
    height: 750px;
    @include media-breakpoint-down(sm) {
      height: 550px;
    }
  }
  .carousel.slide {
    .carousel-inner {
      .carousel-item {
        &.load-bg-img.loaded {
          .overlay {
            background: linear-gradient(
              180deg,
              rgba(25, 13, 0, 0.7),
              rgba(25, 13, 0, 0.1),
              rgba(25, 13, 0, 0)
            );
          }
        }
      }
    }
  }
}
