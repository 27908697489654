

.select-style select:focus {
    outline: none;
}

@mixin form-text-styles {
  background: $color-form-bg;
  @include inner-shadow(none);
  @include box-shadow(none);
  border: $color-form-border-size solid $color-form-border;
  //border-bottom: 2px solid $color-hr;
  padding: 10px !important;
}



::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0,0,0,.2);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0,0,0,.2);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0,0,0,.2);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(0,0,0,.2);
}





html body .gform_wrapper .gform_body .gform_fields {
  input {
    @include transition();
  }
  input[type=text], textarea {
    @include form-text-styles;
  }
  input:focus,
  textarea:focus,
  select:focus,
  {
    border-color: $color-accent !important;
    outline: none;
    @include transition();
  }

  input[type=checkbox]:focus,
  {
    border-color: #4F9AB7 !important;
    box-shadow: none !important;
    outline: none;
    @include transition();
  }

  .gfield {
    padding: 10px 0;
    margin: 10px 0;
  }


  label.gfield_label {
    font-size: 18px;
    //text-transform: uppercase;
    //letter-spacing: .1em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }


  .gfield_checkbox {
		li {
			position: relative;
      line-height: 100%;
			padding-left:40px;
			label {
        padding-left: 30px;
				&:after {
					content:"";
					background:#EFEEED;
          border: 2px solid $color-hr;
					width:24px;
					height:24px;
					display:block;
					position: absolute;
					left:0;
					top:0;
					border-radius:3px;
					z-index: 0;
          @include transition();
				}
				&:before {
					content:"";
					display:block;
				    position: absolute;
				    width: 6px;
					height: 15px;
				    border: solid #fff;
				    border-width: 0 3px 3px 0;
			    	left: 9px;
					top: 4px;
					transform: rotate(45deg);
					opacity:0;
					z-index:1;
					transition:all 100ms;
				}
			}
		}

	}

	input[type="checkbox"] {
		opacity:0;
		outline:none;
		z-index:100;
		width:27px;
		height:27px;
		top:0;
		left:0;
		position: absolute;
		appearance: none;
		cursor: pointer;
    + label {
      cursor: pointer;
    }
		&:checked + label {
      font-weight: 400;
			&:before {
				opacity:1;
				top: 3px;
			}
      &:after {
        content:"";
        background: $color-accent;
        border: 2px solid $color-accent;
      }
		}
	}
}


.gform_wrapper .gform_body li.fl-left,
.gform_wrapper .gform_body li.fl-right {
  display: inline-block;
  width: 48%;
  padding: 0;
  float: left;
  clear: none;
}

.gform_wrapper .gform_body li.fl-left {
  margin-right: 20px;
}

.gform_wrapper .gform_body li.fl-right {
  clear: none;
}
@media handheld, only screen and (max-width: 767px) {
  .gform_wrapper .gform_body li.fl-left,
  .gform_wrapper .gform_body li.fl-right {
    width: 100%;
    padding: 0;
    float: none;
    clear: both;
  }
}
/* 3 column floater */
.gform_wrapper .gform_body li.fl3 {
  display: inline-block;
  width: 31.2%;
  margin-right: 2%;
  padding: 0;
  float: left;
  clear: none;
  @media handheld, only screen and (max-width: 767px) {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  li.fl3.last {
    margin-right: 0;
  }
}











html body {
  /* datepicker css */
  .ui-datepicker {
  background: #fff;
  font-size:11px;
  padding:10px;
  border:1px solid $color-hr;
  width: 300px;
  }

  .ui-datepicker table {
  width: 100%;
  }

  .ui-datepicker table td {
  text-align:center;
  }

  .ui-datepicker a {
  cursor:pointer;
  text-decoration:none;
  }

  .ui-datepicker-prev {
  }

  .ui-datepicker-next {
  float:right;
  }

  .ui-datepicker-title {
  text-align: center;
  font-weight:bold;
  }

  table.ui-datepicker-calendar {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    tr {
      td {
        background: #fff;
        padding: 0 auto;
        a {
          margin: 0;
          background: transparent !important;
          color: $color-text !important;
          border: none;
          color: $color-base;
          text-shadow: none !important;
          border-radius: 0 !important;
          &:hover {
            background: rgba(0, 0, 0, .1) !important;
          }
        }
        &.ui-datepicker-current-day {
          &, a {
            background: $color-accent;
            color: #fff !important;
            border: 0;
            height: auto;
            width: auto;
          }
          a {
            &:hover {
              background: lighten($color-accent, 10%)
            }
          }
        }
      }
    }
  }



}
