body.tax-compliance {
  .header {
    background-color: transparent;
    &.scrollnav {
      background-color: rgba(0, 0, 0, .85);
    }
  }


  .wrap {
    padding-top: 0  !important;
  }
    section.service-header {
    background: $color-accent;
    @media handheld, only screen and (max-width: 768px) {
      padding: 100px 0 50px;
    }
    h1, h2, h3, h4, p {
      color: #fff;
    }
    a.butt {
      background: $color-base;
      &:hover {
        background: #fff;
        color: $color-base;
      }
    }
    .row {
      min-height: 600px;
      @media handheld, only screen and (max-width: 768px) {
        min-height: auto;
      }

    }
    .anim-icons-large {
      margin: 0 auto;
      width: 400px;
      @media handheld, only screen and (max-width: 768px) {
        max-width: 300px;
      }
    }
  }
}

body.virtual-cfo {
  .header {
    background-color: transparent;
    &.scrollnav {
      background-color: rgba(0, 0, 0, .85);
    }
  }

  a:active,
  a:hover,
  a:link,
  a:visited {
    color: $color2;
  }
  a:hover {
    color: #000;
  }

  a.butt {
    color: #fff;
  }

  h1, h2 {
    box-shadow: inset 0 -0.25em rgba(168, 143, 99, .3);
    display: inline;
  }

  .wrap {
    padding-top: 0  !important;
  }
  section.service-header {
    background: $color2;
    @media handheld, only screen and (max-width: 768px) {
      padding: 100px 0 50px;
    }
    h1, h2, h3, h4, p {
      color: #fff;
    }
    a.butt {
      background: $color-base;
      &:hover {
        background: #fff;
        color: $color-base;
      }
    }
    .row {
      min-height: 600px;
      @media handheld, only screen and (max-width: 768px) {
        min-height: auto;
      }

    }
    .anim-icons-large {
      margin: 0 auto;
      width: 400px;
      @media handheld, only screen and (max-width: 768px) {
        max-width: 300px;
      }
    }
  }

  a.butt {
    background: $color2;
  }

  .footer.footer {
    background: $color2;
    background: -moz-linear-gradient(left, $color-base 0%, $color2 50%, $color-base 50%, $color2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $color-base 0%,$color2 50%,$color-base 50%,$color2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-base 0%,$color-base 50%,$color2 50%,$color2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-base', endColorstr='$color2',GradientType=0 ); /* IE6-9 */
    @include media-breakpoint-down(sm) {
      text-align: center;
      background: $color-base;
    }
    .left {
      text-align: center;
      a {
        border-color: $color2;
      }
    }
  }

  .sn {
    a {
      .svg & {
        svg {
          .st0 {
            fill: $color2;
          }
        }
      }
    }
  }

  section.branded {
    //background: $color-base;
  }

  .badge-container {
    .badge {
      background: url("#{$images}/gc-badge-tan.png") center center no-repeat;
      background-size: 220px auto;
    }
  }

}





body.virtual-hr {
  .header {
    background-color: transparent;
    &.scrollnav {
      background-color: rgba(0, 0, 0, .85);
    }
  }

  a:active,
  a:hover,
  a:link,
  a:visited {
    color: $color3;
  }
  a:hover {
    color: #000;
  }

  a.butt {
    color: #fff;
  }

  h1, h2 {
    box-shadow: inset 0 -0.25em rgba(109, 163, 162, .3);
    display: inline;
  }

  .wrap {
    padding-top: 0  !important;
  }
  section.service-header {
    background: $color3;
    @media handheld, only screen and (max-width: 768px) {
      padding: 100px 0 50px;
    }
    h1, h2, h3, h4, p {
      color: #fff;
    }
    a.butt {
      background: $color-base;
      color: #fff;
      &:hover {
        background: #fff;
        color: $color-base;
      }
    }
    .row {
      min-height: 600px;
      @media handheld, only screen and (max-width: 768px) {
        min-height: auto;
      }

    }
    .anim-icons-large {
      margin: 0 auto;
      width: 400px;
      @media handheld, only screen and (max-width: 768px) {
        max-width: 300px;
      }
    }
  }

  a.butt {
    background: $color3;
  }

  .footer.footer {
    background: $color3;
    background: -moz-linear-gradient(left, $color-base 0%, $color3 50%, $color-base 50%, $color3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $color-base 0%,$color3 50%,$color-base 50%,$color3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-base 0%,$color-base 50%,$color3 50%,$color3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-base', endColorstr='$color3',GradientType=0 ); /* IE6-9 */
    @include media-breakpoint-down(sm) {
      text-align: center;
      background: $color-base;
    }
    .left {
      text-align: center;
      a {
        border-color: $color3;
      }
    }
  }

  .sn {
    a {
      .svg & {
        svg {
          .st0 {
            fill: $color3;
          }
        }
      }
    }
  }

  .badge-container {
    .badge {
      background: url("#{$images}/gc-badge-green.png") center center no-repeat;
      background-size: 220px auto;
    }
  }
}
