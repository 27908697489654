.navbar-container {
  position: fixed;
  display: none;
  @include opacity(0);
  @include transition(0.1s);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: $color-base;
  background: rgba(255, 255, 255, 0.96);
  @media handheld, only screen and (max-width: 992px) {
    &.showing {
      display: block;
    }

    &.show {
      @include opacity(1);
    }
  }
}

.navbar-toggler {
  display: none;
  border: none !important;

  &:before,
  &:after {
    display: none;
  }

  &:hover {
    background: transparent;
  }

  .icons {
    width: 25px;
    height: 25px;
    position: relative;
  }

  .icon-close,
  .icon-menu {
    display: block;
    @include transition(0.2s);
    width: 25px;
    height: 25px;
    position: absolute;
    top: -6px;
    left: 0;

    svg {
      width: 20px;
      height: 20px;
      @include transition(0.2s);
      .st0 {
        fill: none;
        stroke: $color-base;
        stroke-width: 2.5;
        stroke-miterlimit: 10;
      }
    }
  }

  header.scrollnav &,
  body.home & {
    .icon-close,
    .icon-menu {
      svg {
        .st0 {
          stroke: #fff;
        }
      }
    }
  }
  html.show-nav & {
    .icon-close,
    .icon-menu {
      svg {
        .st0 {
          stroke: $color-base;
        }
      }
    }
  }

  .icon-menu {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .icon-close {
    display: none;
    @include opacity(0);
    svg {
      .st0 {
        stroke: $color-hamburger-menu-open;
      }
    }
  }

  &.showing {
    .icon-menu {
      @include opacity(0);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .icon-close {
      display: block;
    }
  }

  &.show {
    .icon-menu {
      display: none;
      width: 20px;
      height: 20px;
    }

    .icon-close {
      @include opacity(1);

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media handheld, only screen and (max-width: 992px) {
    display: block;
    position: absolute;
    top: 24px;
    right: 10px;
    z-index: 9999;
    //width: 60px;
  }
}

nav.navbar {
  margin: 0;
  padding: 0;

  .nav {
    transition: none;
    @include transition(0.1s);

    @include flex-justify-start;
    @media handheld, only screen and (max-width: 992px) {
      @include opacity(0);
      background: none;
      height: auto;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      padding-top: 50px;

      &.show {
        @include opacity(1);
      }
    }

    ul {
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;
      @media handheld, only screen and (max-width: 992px) {
        width: 100%;
      }

      & > li {
        padding: 0;
        margin: 0 10px;
        vertical-align: middle;
        //border-right: 1px solid rgba(0, 0, 0, .1);

        @media handheld, only screen and (max-width: 992px) {
          padding-bottom: 1px solid transparent;
        }

        a {
          font-family: $font-menu;
          -webkit-transition: color 0.2s ease-out;
          -moz-transition: color 0.2s ease-out;
          -o-transition: color 0.2s ease-out;
          transition: color 0.2s ease-out;
          font-size: 17px;
          text-transform: uppercase;
          letter-spacing: 0.11em;
          line-height: 100%;
          font-weight: 500;
          display: block;
        }

        a.nav-link {
          color: $color-base;
          padding: 13px 20px;
          border: 1px solid transparent;
          @include flex-it;
          @include flex-align-item-center;
          @media handheld, only screen and (max-width: 1150px) {
            padding-left: 6px;
            padding-right: 6px;
            color: $color-base;
          }
          &:hover {
            @include opacity(0.85);
            @media handheld, only screen and (max-width: 992px) {
              color: $color-base;
            }
          }

          @media handheld, only screen and (max-width: 992px) {
            display: block;
            font-size: 25px;
          }
        }

        &.current-menu-item,
        &.current_page_ancestor {
          a.nav-link {
            //color: #fff;
            @include rounded(3px);
            @media handheld, only screen and (max-width: 992px) {
              border: none;
            }
          }
        }

        &.dropdown > a::after {
          content: "";
          background: none;
          border: none;
          line-height: 100%;
          margin: 2px 0 0 4px;
          min-height: auto;
          padding: 0;
          height: auto;
          border-top: 5px solid rgba(0, 0, 0, 0.8);
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          @include transition();
          @media handheld, only screen and (max-width: 992px) {
            border-top: 5px solid rgba(0, 0, 0, 0.8);
          }
        }

        &.dropdown.show {
          a.nav-link {
          }
          a::after {
            border-top-color: $color-accent;
            @include rotation(180deg);
          }
        }

        a.dropdown-item {
          color: $color-base;
          font-size: 22px;
          line-height: 100%;
          padding: 9px 15px;
          display: block;
          position: static;
          font-size: 17px;
          -webkit-transition: color 0.2s ease-out;
          -moz-transition: color 0.2s ease-out;
          -o-transition: color 0.2s ease-out;
          transition: color 0.2s ease-out;
          &:hover {
            background: transparent;
            color: $color-accent;
          }

          &.active {
            background: transparent;
            color: $color-accent;
          }
        }

        &.mobile-only {
          display: none;
          @media handheld, only screen and (max-width: 992px) {
            display: block;
          }
        }

        @media handheld, only screen and (min-width: 992px) {
          &.order {
            margin-left: auto;
            margin-right: 0;
            border: 1px solid $color-accent;
            color: #fff;
            display: block;
            @include rounded(3px);
            @include transition();
            &:hover {
              background: $color-accent;
            }
          }
        }
      }

      .dropdown-menu {
        border: none;
        background: #fff;
        @include drop-shadow(0, 0.25, 19px, 0);
        min-width: 220px;
        @include rounded(3px);
        @include opacity(0);
        top: 80%;
        @include transition(0.1s);
        &.inplace {
          @include opacity(1);
          top: 95%;
        }
        hr {
          margin: 10px 0;
        }
        h3 {
          padding: 7px 10px;
          margin: 0;
          margin-top: 13px;
          font-size: 15px;
          background: $color-accent-secondary;
          font-family: $font-body;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #fff;
          font-weight: 500;
        }
        @media handheld, only screen and (max-width: 992px) {
          background: $color-base;
          @include rounded(0);
          border: none;
          @include drop-shadow(0, 0, 0, 0);
          padding: 15px 0;

          a.dropdown-item {
            //color: #fff;
            padding: 10px 15px;
            font-size: 18px;
            @include rounded(0);

            @media handheld, only screen and (max-width: 992px) {
              color: #fff;
              font-size: 19px;
              padding: 15px 25px;
            }

            &.active {
              background: transparent;
              color: #fff;
            }
          }
        }
      }

      .dropdown-menu::before {
        content: "";
        display: block;
        vertical-align: top;
        width: 0;
        height: 0;
        margin-top: -3px;
        margin-right: 0;
        vertical-align: middle;
        border-bottom: 8px solid #fff;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        position: absolute;
        z-index: 8001;
        top: -4px;
        left: 25px;
        @media handheld, only screen and (max-width: 992px) {
          left: 30px;
          top: 50px;
          border-bottom: 8px solid $color-base;
        }
      }
    }
  }
}

.search-icon {
  img {
    width: 30px;
  }
  @media handheld, only screen and (max-width: 992px) {
    display: none;
  }
}

.the-search-form {
  position: fixed;
  display: none;
  @include opacity(0);
  @include transition(0.2s);
  width: 100% !important;
  height: 100px !important;
  top: 85px;
  box-sizing: border-box;
  padding: 20px;
  left: 0;
  height: 100px;
  background: rgba(23, 45, 54, 0.96);
  @media handheld, only screen and (max-width: 992px) {
    height: auto !important;
    margin: 40px 0 0;
    padding: 0;
    position: static;

    input {
      background: rgba(255, 255, 255, 0.1);
    }

    html.show-nav &,
    html.show-nav &.collapse {
      display: block;
      @include opacity(1);
    }
  }

  &.collapsing {
    display: block;
    width: 100% !important;
    height: 100px !important;
    left: 0;
  }

  &.show {
    @include opacity(1);
    display: block;
    top: 94px;
    @media handheld, only screen and (min-width: 992px) {
      .headroom--unpinned & {
        //display: none;
        @include opacity(0);
        top: -50px;
      }
    }
  }
  @media handheld, only screen and (max-width: 992px) {
    position: static;
    height: auto;
    width: auto;
  }
  @media handheld, only screen and (max-width: 992px) {
    &.showing {
      display: block;
    }

    &.show {
      @include opacity(1);
    }
  }

  a.icon-close {
    position: absolute;
    top: 35px;
    right: 50px;
    z-index: 9950;
    @include transition();
    @include opacity(0);
    transition-delay: 0.1s;
    @media handheld, only screen and (max-width: 992px) {
      display: none;
    }
  }

  &.show {
    a.icon-close {
      @include opacity(1);
    }
  }

  form {
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;
    font-size: 0;

    label {
      margin: 0;
      padding: 0;
    }
    // Search form
    input.search-field,
    input.search-submit {
      padding: 20px;
      box-sizing: border-box;
      height: 60px;
      @include rounded(none);
      border-radius: 0 !important;
      border: 0;
      font-size: 21px;
      font-weight: 500;
      @media handheld, only screen and (max-width: 992px) {
        padding: 10px;
        font-size: 19px;
        height: 50px;
      }
    }

    input.search-field {
      background: rgba(255, 255, 255, 0.9);
      min-width: 500px;
      @media handheld, only screen and (max-width: 992px) {
        min-width: 100%;
      }
    }

    input.search-submit {
      background: $color-accent-secondary;
      color: #fff;

      &:hover {
        background: $color-accent;
      }
    }
  }
}
// Secondary navigation
section.secondary-nav-heading {
  margin: 30px 0;

  p {
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-family: $font-headline;
    line-height: 100%;
  }
}

section.secondary-nav {
  border-top: 1px solid $color-hr;
  border-bottom: 1px solid $color-hr;

  ul {
    &,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    & {
      margin-left: -15px;
    }

    li {
      display: inline-block;
      position: relative;
      a {
        //color: #fff;
        color: $color-accent-secondary;
        display: block;
        padding: 10px 15px;
        border: 0;
        &:hover {
          color: $color-accent;
        }
      }
    }

    li.current-menu-item {
      a {
        color: #fff;
        background: $color-accent;
      }
    }

    li:first-child.current-menu-item {
      margin-left: 15px;
    }

    li.current-menu-item::after {
      content: "";
      display: block;
      vertical-align: top;
      width: 0;
      height: 0;
      margin-top: -1px;
      margin-left: -4px;
      vertical-align: middle;
      border-top: 8px solid $color-accent;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      position: absolute;
      z-index: 20;
      left: 50%;
    }
  }
}

// Breadcrumbs
section.breadcrumbs {
  //border-bottom: 1px solid $color-hr;
  background: #f2f4f7;
  padding: 10px 45px;
}

.bc {
  color: $color-accent;
  font-size: 16px;

  a {
    border-color: #91b0b7;
  }

  span::after {
    content: "\2192";
    display: inline-block;
    margin: 0 5px;
    color: $color-accent;
    @include opacity(0.5);
  }

  span:last-child::after {
    display: none;
  }
}

.apply-for-grant {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
.list-group {
  padding-top: 40px;
}
.list-group {
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .list-group-item {
    font-family: $font-headline;
    padding: 10px;
    line-height: 100%;
    &:hover {
      background: $color-accent-secondary;
      color: #fff;
    }
    &.active {
      background: $color-accent;
      border-color: $color-accent;
    }
  }

  &.stickit {
    margin-top: 120px;
    position: fixed;
    top: 0;
  }
  &.hideit {
    display: none !important;
  }
}

.scrollspy {
  height: 500px;
  padding: 0 25px 25px 25px;
  padding-bottom: 340px;
  box-sizing: border-box;
  overflow: auto;
  background: #fff;
  @include media-breakpoint-down(sm) {
    height: auto;
    text-align: left;
    padding: 15px;
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
  hr {
    //margin-bottom: 10px;
  }
  h3 {
    padding-top: 25px !important;
  }
}
