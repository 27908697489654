section.menu {
  margin-bottom: 50px;
  background: #fff;
  h2 {
    background: #fff;
    display: block;
    text-align: center;
    line-height: 100%;
    font-size: 70px;
    text-transform: uppercase;
    //height: 45px;
    margin-bottom: 30px;
    display: block;
    background: url("#{$images}/double-lines.svg") center center repeat-x;
    border: none;
    background-size: 15px auto;
    padding: 0;
    span {
      background: #fff;
      padding: 20px;
      display: inline-block;
    }
  }
  p.menu-desc {
    text-align: center;
  }
  h3 {
    border-bottom: 1px solid $color-base;
    display: inline-block;
    padding-bottom: 10px;
    line-height: 100%;
    font-size: 37px;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: block;
  }

  .col-md-6:not(.last) {
    li.last {
      border: none;
      @media handheld, only screen and (max-width: 768px) {
        //border-bottom: 1px solid $color-accent;
      }
    }
  }
  .col-md-6.last {
    //border-left: 1px solid $color-accent;
  }
  ul {
    &,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  ul.sections {
    & > li {
      //border-bottom: 1px solid $color-accent;
      padding: 30px 0;
    }

    li {
      em {
        margin-bottom: 15px;
        display: block;
      }
    }

    li.last {
      border: none;
    }

    ul {
      li {
        border-bottom: 1px solid $color-hr;
        padding: 10px 0;
        h4 {
          margin-bottom: 0px;
          font-size: 23px;
        }
        p {
          @include flex-it;
          @include flex-align-item-center;
        }
        b {
          font-weight: 500;
          width: 200px;
          font-size: 18px;
          color: $color-base;
          display: inline-block;
          //margin-bottom: 4px;
          width: auto;
        }
        img {
          width: 17px;
          height: 17px;
          margin-left: 5px;
        }

        p {
          width: 87%;
          margin: 4px 0 0 0;
          padding: 0;
          line-height: 130%;
          font-size: 16px;
          color: #665a5f;
        }
        p.sb {
          font-size: 14px;
          color: $color-accent;
        }
        div.price {
          float: right;
          width: 10%;
          font-weight: 500;
          text-align: right;
          margin: 0;
          padding: 0;
        }
      }
      li:last-child {
        border: 0 !important;
      }
    }
  }
}
