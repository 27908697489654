.footer.footer {
  margin: 0 !important;
  text-align: left;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  .footer-inner {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  &,
  p,
  ul li {
    font-size: 18px;
  }

  h3 {
    font-size: 23px;
  }

  a {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    &:hover {
      color: #fff;
      border-color: $color-accent;
    }
  }

  p {
    margin: 0;
  }
  a.butt {
    padding: 12px;
  }
  a.butt {
    background: $color-accent-third;
  }
  a.butt:hover {
    background: $color-accent-secondary;
  }

  nav {
    display: block;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    ul {
      &,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      li {
        padding: 2px 0;
      }
    }
  }

  .left {
    text-align: left;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    img {
      width: 180px;
      height: auto;
    }
    p.addr {
      margin: 20px 0 7px;
    }
    a {
      border-color: $color-accent;
    }
  }

  .left-center {
    .gform_wrapper {
      margin: 0;
      padding: 0;
    }
    .gform_wrapper form {
      color: #fff;
      padding: 0;
      margin: 0;
      .gform_body {
        padding: 0;
        margin: 0;
        flex: 1;
      }
      .gform_footer {
        margin: 0;
        padding: 0;
        flex: 1;
      }
      .validation_error {
        display: block;
        color: #fff;
        border-color: #fff;
        width: 100%;
      }
      .validation_message {
        color: #fff;
      }
      input {
        box-sizing: border-box;
        height: 50px !important;
      }
      .gform_body {
        width: 80%;
        @include media-breakpoint-down(md) {
          max-width: 900px;
          width: 100%;
        }
        ul.gform_fields {
          @include media-breakpoint-down(md) {
            width: 100% !important;
            max-width: auto !important;
          }
        }
        ul.gform_fields,
        ul.gform_fields li,
        .ginput_container {
          margin: 0 !important;
          padding: 0 !important;
        }
        input {
          margin: 0 !important;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .gform_footer {
        width: 20%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        input {
          margin: 0 !important;
          min-width: 80px;
          box-sizing: border-box;
          &:hover {
            background: $color-base;
          }
          @include media-breakpoint-down(md) {
            width: 100% !important;
            padding: 10px;
            max-width: 900px;
            min-width: 100%;
            display: block;
          }
        }
      }
      @include flex-it;
      @include flex-justify-start;
      @include flex-align-item-center;
      //@include flex-wrap;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  .right {
    text-align: right;
  }

  @include media-breakpoint-down(sm) {
    .left,
    .left-center,
    .right-center {
      padding-bottom: 15px;
    }
    .left-center,
    .right-center,
    .right {
      padding-top: 15px;
    }
  }

  .sn {
    @include flex-justify-start;
    text-align: right;

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      @include flex-justify-center;
    }
  }

  .legal {
    text-align: center;
    padding-bottom: 20px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      span {
        display: block;
        margin-top: 3px;
      }
    }
    &,
    p {
      color: #4f4f4f;
      font-size: 14px;
    }
    p {
      margin-bottom: 15px;
    }
    a {
      color: #4f4f4f;
    }

    .copyright {
      &,
      p,
      a {
        color: lighten(#4f4f4f, 30%);
      }
      p {
        margin: 0;
      }
    }

    padding: 20px 0;
    .broker-check {
      text-align: right;
    }
  }

  table {
    margin: 0 0 0 auto;
    @include media-breakpoint-down(md) {
      margin: 0;
      width: 100%;
    }
    tr {
      td {
        font-size: 17px;
        padding-right: 10px;
      }
      td:list-child {
        padding-right: 0;
      }
    }
  }
}

table.mabel-bhi-businesshours {
  tr {
    td {
      font-size: 17px;
      padding: 4px 8px;
      border-top: 1px solid $color-hr;
      text-align: left;
    }
    &:first-child td {
      border-top: none;
    }
    td.mabel-bhi-day {
      text-align: right;
    }

    &.mbhi-is-current td {
      border-top: 1px solid $color-accent !important;
      border-bottom: 1px solid $color-accent;
      font-weight: 500;
    }
  }
}
