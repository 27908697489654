ul.filters {
  list-style: none;
  font-size: 0;
  @include flex-it;
  @include flex-align-item-center;
  @include flex-justify-center;
  &, li {
    margin: 0;
    padding: 0;
  }
  margin-top: 30px;
  li {
    margin: 5px;
  }
}


button.facetwp-map-filtering {
  display: none;
}

.reset-button {
    background: $color-accent;
    border-color: $color-accent !important;
    color: #fff;
    &.disabled {
      display: none;
    }
    &:hover {
      background: $color-accent-secondary;
      border-color: $color-accent-secondary !important;
    }
  }

  html body .facetwp-facet {
    input[type=text] {
      @include form-text-styles;
    }
  }
  html body .facetwp-facet.facetwp-type-proximity {
    max-width: 500px;
    min-height: 0;
    margin: 0 auto 30px;
    padding: 0;
    @include flex-it;
    @include flex-align-item-center;

    .location-wrap {
      background: $color-accent;
      margin: 0;
      padding: 0;
      width: 100%;
      input.facetwp-location {
        margin: 0;
        width: 100%;
      }
    }

    .nice-select {
      width: 150px;
      margin: 0 0 0 2px !important;
    }
  }




.facetwp-type-fselect {
  .fs-label-wrap {
    .fs-label {
      cursor: pointer;
      @include form-text-styles;
      text-align: left;
    }
  }
  .fs-dropdown {
    .fs-options {
      background: $color-form-bg;
      .fs-option {
        background: $color-form-bg;
        @include transition();
        cursor: pointer;
        text-align: left;
        &:hover {
          background: $color-accent;
          color: #fff;
        }

        &.selected {
          background: rgba(0, 0, 0, .05);
          color: $color-text;
        }
      }
    }
  }
}



// LOading styles.
.facetwp-template.loading {
  @include transition();
  @include opacity(.4);
}


// Menu facet styles
.facetwp-facet-syracuse_menu {
  @include flex-it;
  @include flex-justify-center;
  .facetwp-radio {
    padding: 20px;
    background: $color-accent-secondary;
    border: 3px solid $color-accent-secondary;
    background-image: none !important;
    margin: 1px;
    &.checked {
      border: 3px solid $color-accent;
      color: $color-base;
      background: #fff;
      font-weight: 500;
    }
  }
}

// Hide the counter
.facetwp-counter {
  display: none;
}
