section.pghead {
  .row {
    div.col {
      background: url("#{$images}/grey-line.png") center center repeat-x;
      background-size: auto 1px;
      padding: 0;
    }
    padding-left: 30px;
    padding-right: 30px;
  }
  h1,
  h2 {
    background: #fff;
    margin: 0;
    padding: 0;
    line-height: 100%;
    display: inline-block;
    padding: 0 20px 0 0;
  }
  h1 {
    font-size: 30px;
  }
}

.page-heading {
  text-align: center;
}

.slider-heading {
  //display: none;

  //@media handheld, only screen and (max-width: 992px) {
  display: block;
  position: absolute;
  z-index: 100;
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: 180px;
  }
  //}
  @media handheld, only screen and (max-width: 768px) {
    img {
      width: 280px;
      top: 120px;
    }
  }
}

.intro {
  //position: absolute;
  position: relative;
  top: -130px;
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: -75px;
  text-align: center;

  @media handheld, only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sn {
    @include flex-justify-center;
  }

  .open-status {
    border: 1px solid $color-hr;
    padding: 15px;
    margin: 20px 0;
  }

  .mabel-bhi-businesshours {
    margin: 0 auto 0;
  }

  h1 {
    font-size: 65px;
    text-align: center;
    @media handheld, only screen and (max-width: 768px) {
      font-size: 50px;
    }
  }
  a.butt {
    margin: 0 auto;
    @media handheld, only screen and (max-width: 768px) {
      display: block;
      margin: 10px 0;
    }
  }
  .row {
    background: #fff;
    margin: 0 10px;
    padding: 40px 30px;
    @include rounded(15px);

    @media handheld, only screen and (max-width: 768px) {
      padding: 0;
      margin: 0;
      .col {
        padding: 20px;
      }
    }
  }
}

.intro-blocks {
  .inner {
    background: #fff;
    padding: 40px 30px;
    @include rounded(3px);
    box-sizing: border-box;
    height: 100%;
    text-align: center;
  }
}

.facet-menu {
  .container-fluid {
    margin: 0;
    padding: 0;
    .row {
      padding: 0;
      margin: 0;
    }
  }
}

section.stylized-img {
  img {
    width: 100%;
    height: auto;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
}
